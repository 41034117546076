(() => {
    const feedbackButton = document.querySelector('#feedback-trigger');
    const observer = new MutationObserver(() => {
        if (document.querySelector('.touchpoint')) {
            feedbackButton.classList.add("open");
        } else {
            feedbackButton.classList.remove("open");
        }
    });

    observer.observe(document.querySelector('body'), { childList: true });
    
    feedbackButton.onclick = (event) => {
        const isSurveyOpen = document.querySelector('.touchpoint');
        
        if (isSurveyOpen) {
            Touchpoint.onCollapseActivity();
            return;
        }

        if (window.Touchpoint) {
            showTouchpoint();
            feedbackButton.classList.add("open");
        } else {
            window.addEventListener("TouchpointLoaded", () => {    
                feedbackButton.classList.add("open");
                showTouchpoint();
            });
        }
    }
})()